.container {
    text-align: center;
    }
    .toggle-switch {
    position: relative;
    width: 100%;
    display: flex;
    text-align: left;
    top: 8px;
    align-items: center;
    font-size: 13px;
    margin-bottom: 11px;
    }
    .checkbox {
    display: none;
    }
    .label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 0 solid #bbb;
        border-radius: 20px;
        height: 10px;
        width: 27px;
        border: 1.5px solid;
        margin-right: 5px;
    }
    .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    }
    .inner:before,
    .inner:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
    }
    .inner:before {
    content: "YES";
    padding-left: 10px;
    background-color: #fff;
    color: #fff;
    }
    .inner:after {
    content: "NO";
    padding-right: 10px;
    color: #fff;
    text-align: right;
    }
    .switch {
        display: block;
        width: 10px;
        margin: 5px;
        background: #000;
        position: absolute;
        top: -1.1px;
        bottom: 0;
        right: 428px;
        border: 0 solid #bbb;
        border-radius: 20px;
        transition: all 0.3s ease-in 0s;
        height: 51%;
    }
    .checkbox:checked + .label .inner {
    margin-left: 0;
    }
    .checkbox:checked + .label .switch {
        right: 415px;
    }
    